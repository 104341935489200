import axios from 'axios';
import qs from 'qs';


// 超时设定
axios.defaults.timeout = 25000;
axios.defaults.withCredentials = false;
axios.baseURL = process.env.VUE_APP_BASE_API

axios.interceptors.request.use(config => {
    return config;
}, err => {
  return Promise.resolve(err);
});

axios.interceptors.response.use(response => {
  const data = response.data;
  return data;
}, (err) => {
  return Promise.resolve(err);
});


export const getRequest = (url, params) => {
  return axios({
    method: 'get',
    url: `${axios.baseURL}${url}`,
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    },
    headers: {}
  });
};

export const postRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${axios.baseURL}${url}`,
    data: params,
    transformRequest: [function(data) {
      return JSON.stringify(data);
    }],
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  });
};

export const exportRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${axios.baseURL}${url}`,
    responseType: 'arraybuffer',
    data: params,
    transformRequest: [function(data) {
      return JSON.stringify(data);
    }],
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  });
};

export const putRequest = (url, params) => {
  return axios({
    method: 'put',
    url: `${axios.baseURL}${url}`,
    data: params,
    transformRequest: [function(data) {
      delete data.isDeleted;
      delete data.creator;
      delete data.createDate;
      return JSON.stringify(data);
    }],
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  });
};

export const uploadFileRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${axios.baseURL}${url}`,
    params: params,
    headers: {}
  });
};
