import Vue from 'vue'
import App from './App.vue'
import router from './router'

import elementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import { initDingH5RemoteDebug } from "dingtalk-h5-remote-debug";
initDingH5RemoteDebug();

Vue.config.productionTip = false
Vue.use(elementUI);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
