import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/direct',
    name: 'direct',
    meta:{
      title:'直接用餐'
    },
    component: () => import('../views/direct.vue')
  },
  {
    path: '/giveHand',
    name: 'giveHand',
    component: () => import('../views/giveHand.vue')
  },
  {
    path: '/mealStatistics',
    name: 'mealStatistics',
    component: () => import('../views/mealStatistics.vue')
  },
  {
    path: '/yishu-search',
    name: 'yishuSearch',
    component: () => import('../views/yishu/search.vue')
  },
  {
    path: '/yishu-content',
    name: 'yishuContent',
    component: () => import('../views/yishu/content.vue')
  },
]

const router = new VueRouter({
  mode: process.env.NODE_ENV === "development" ? "hash" : 'history',
  routes
})

export default router
