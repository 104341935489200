<template>
  <div class="home flex">
    <div v-if="!loading" class="content flex">
      <div class="dateTime">{{dateTime}}</div>
      <div class="user-info flex" v-if="isState" :class="istrue ? 'bgrGreen' :'borderDanger'">
        <div class="user-name">{{ mealInfo.name }} </div>
        <div class="time">{{ time }}</div>
      </div>
      <div class="user-info flex otherState" v-else>
        <div class="user-name">{{ mealInfo.name }} </div>
        <div class="time">{{ time }}</div>
      </div>
      <div class="msg-text" v-if="isState" :class="istrue ? 'green' : 'danger'"> {{ mealInfo.msg }} </div>
      <div class="msg-text" v-else style="color:#faad14"> {{ mealInfo.msg }} </div>
      <div class="button-list flex">
        <van-button 
          v-if="mealInfo.code == 1000 || mealInfo.code == 1001 || mealInfo.code == 1002" 
          type="info"
          block
          style="margin-bottom:15px" 
          :disabled="isDisabled" 
          @click="handleconfirmMeal">确认用餐
        </van-button>
        <van-button 
          v-if="mealInfo.code == 504 || mealInfo.code == 503 || mealInfo.code == 502" 
          type="info"
          block
          style="margin-bottom:10px" 
          @click="handleDirectMeal">直接用餐
        </van-button>
        <van-button block type="warning" @click="handleGiveHand">代领餐</van-button>
      </div>
    </div>
    <div v-else><van-loading type="spinner" /></div>
  </div>
</template>

<script>
import * as dd from 'dingtalk-jsapi';
import Vue from 'vue';
import { Button,Loading,Toast,Dialog } from 'vant';

import { getMealCode,confirmMeal } from '@/api'
Vue.use(Button);
Vue.use(Loading);
Vue.use(Toast)
Vue.use(Dialog)

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      loading:true,
      istrue:null,
      isState:true,
      mealInfo:{},
      dateTime:'',
      time:'',
      isDisabled:false
    };
  },
  mounted() {
    this.init();
    this.getTime()
    window.setInterval(() =>{
      this.getTime()
    },1000)
  },
  
  methods: {
    init() {
      let that = this
      // 企业的corpid
      let corpId = 'ding3cf3b09b32354bcf35c2f4657eb6378f';
      // 通过dd.env.platform检查当前是否在钉钉环境下
      if (dd.env.platform !== "notInDingTalk") {
        dd.ready(function() {
          // 获取免登授权码
          dd.runtime.permission.requestAuthCode({
            corpId, // 企业id
            onSuccess: (res) => {
              const { code } = res; // 通过该免登授权码可以获取用户身份
              that.getAuthor(code)
            },
            onFail: (err) => {
              console.log(err)
            },
            complete: (res) => {
              console.log(res)
            }
          });
        });
      }
    },
    // 获取用户的信息，然后完成跳转
    async	getAuthor(code) {
      this.loading = true
      let res = await getMealCode(code)
      //alert(JSON.stringify(res))
      if(res.code == '200') {
        this.loading = false
        this.mealInfo = JSON.parse(JSON.stringify(res.result))
        //this.mealInfo.code = 1000
        switch(this.mealInfo.code) {
          case 1000 :
            this.isState = true
            this.istrue = true
            break;
          case 1001 :
          case 1002 :
            this.isState = false
            this.istrue = true
            break;
          case 504 :
          case 503 :
          case 502 :
            this.istrue = false
            break;
        }
      }
    },
    getTime(){
      let date = new Date();
      //年 getFullYear()：四位数字返回年份
      let year = date.getFullYear(); //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      let month = date.getMonth() + 1;
      //日 getDate()：(1 ~ 31)
      let day = date.getDate();
      //时 getHours()：(0 ~ 23)
      let hour = date.getHours();
      //分 getMinutes()： (0 ~ 59)
      let minute = date.getMinutes();
      //秒 getSeconds()：(0 ~ 59)
      let second = date.getSeconds();
      
      let dateTime = year +'-' + month+'-'+ this.addZero(day)
      let time = this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);

      this.dateTime = dateTime
      this.time = time
    },
    //小于10的拼接上0字符串
    addZero(s) {
      return s < 10 ? ('0' + s) : s;
    },

    async handleconfirmMeal(){
      this.isDisabled = true
      let res = await confirmMeal(this.mealInfo.name)
      
      if(res.code == '200') {
        this.isDisabled = false
        Dialog.alert({
          confirmButtonText: '关闭',
          message: res.result+ '，祝您用餐愉快!'+ '\n 备注：此弹窗需给打饭工作人员查看，\n确认后再关闭！'
        }).then(() => {
          location.reload()
          // this.$router.push({
          //   name: "Home",
          // });
        });
      }else{
        this.isDisabled = false
        Toast.fail('提交失败，请重试！');
      }
    },
    handleDirectMeal(){
      this.$router.push({
        name: "direct",
        params: this.mealInfo,
      });
    },
    handleGiveHand(){
      this.$router.push({
        name: "giveHand",
        params: this.mealInfo,
      });
    }
  },
}
  
</script>

<style scoped>
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .home {
    height:100%;
    width: 100%;
    position: absolute;
  }
  .content {
    width: 100vw;
    height: 100vh;
    flex-flow: column;
  }
  .user-info {
    width: 47vw;
    height: 47vw;
    border-radius: 50%;
    flex-flow: column;
  }
  .user-name {
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 10px;
  }
  .dateTime {
    color: #333;
    font-size: 32px;
    margin-bottom: 40px;
  }
  .time {
    color: #ffffff;
    font-size: 24px;
  }
  .bgrGreen {
    background-color: #5acd7d;
  }
  .otherState {
    background-color: #faad14;
  }
  .borderDanger {
    background-color: #ff5f4e;
  }
  .green {
    color: #5acd7d;
  }
  .danger {
    color: #ff5f4e;
  }
  .msg-text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 24px;
  }
  .button-list {
    height: 20vh;
    width: 50vw;
    align-items: flex-end;
    flex-flow: column;
  }
</style>