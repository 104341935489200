import {
  exportRequest,
  postRequest,
  getRequest
} from "../utils/request";

const baseURL = process.env.NODE_ENV === 'production' ? '' : '/pikachu'

//用餐
export const getMealCode = (code) => {
  return postRequest(`${baseURL}/mealReport/getMeal/${code}`)
}
//确认用餐
export const confirmMeal = (user) => {
  return postRequest(`${baseURL}/mealReport/confirm/${user}`)
}

//直接吃餐
export const getMealEatInfo = (params) => {
  return postRequest(`${baseURL}/mealReport/eat`, params)
}

//代领已报餐
export const getOthersLead = (params) => {
  return postRequest(`${baseURL}/mealReport/getOthers`, params)
}

export const queryPageList = (data) =>{
  return postRequest(`${baseURL}/mealReport/list`,data)
}

//导出
export const exportMeals = (data) =>{
  return exportRequest(`${baseURL}/mealReport/exportMeals`,data)
}

export const getYishuCal = (params) => {
  return getRequest(`${baseURL}/yishu/cal/${params}`)
}
